<template>
  <div class="layout">
    <div class="container d-flex flex-column">
      <div
        class="row align-items-center justify-content-center no-gutters min-vh-100"
      >
        <div class="col-12 col-md-5 col-lg-4 py-8 py-md-11">
          <div class="text-center mb-10">
            <img
              :src="logoURL"
              class="mx-auto fill-primary"
              data-inject-svg
              alt
              style="height: 76px;"
            />
          </div>
          <!-- Heading -->
          <h1 class="font-bold text-center">{{ $t('resetPassword.title') }}</h1>

          <!-- Text -->
          <p class="text-center mb-6">
            {{ $t('resetPassword.subTitle') }}
          </p>

          <div class="alert alert-danger" role="alert" v-if="hasError">
            {{ $t('resetPassword.error') }}
          </div>

          <!-- Form -->
          <form class="mb-6" @submit="resetPassword">
            <!-- Email -->
            <div class="form-group">
              <label for="password" class="sr-only">{{
                $t('resetPassword.password')
              }}</label>
              <input
                type="password"
                class="form-control"
                id="password"
                :placeholder="$t('resetPassword.placeholders.password')"
                autocomplete="off"
                v-model="password"
              />
            </div>

            <!-- Password -->
            <div class="form-group">
              <label for="passwordConfirmation" class="sr-only">{{
                $t('resetPassword.passwordConfirmation')
              }}</label>
              <input
                type="password"
                class="form-control"
                id="passwordConfirmation"
                :placeholder="
                  $t('resetPassword.placeholders.passwordConfirmation')
                "
                autocomplete="off"
                v-model="passwordConfirmation"
              />
            </div>

            <!-- Submit -->
            <button
              class="btn btn-block btn-primary"
              type="submit"
              :disabled="!isValid"
            >
              {{ $t('resetPassword.submit') }}
            </button>
          </form>
        </div>
      </div>
      <!-- / .row -->
    </div>
  </div>
  <!-- .layout -->
</template>

<script>
import { mapActions } from 'vuex';
import { setAgentPassword } from '@/shared/api';

export default {
  name: 'ResetPassword',
  props: ['agentId', 'passwordToken'],
  data() {
    return {
      hasError: false,
      password: '',
      passwordConfirmation: '',
    };
  },
  methods: {
    ...mapActions(['displayMessageAction']),
    resetPassword(event) {
      event.preventDefault();

      if (setAgentPassword(this.agentId, this.passwordToken, this.password)) {
        this.hasError = false;
        this.displayMessageAction({
          type: 'success',
          text: this.$i18n.t('resetPassword.success'),
        });
        this.$router
          .push({ name: 'signIn' })
          .catch(error => console.log(error));
      } else this.hasError = true;
    },
  },
  computed: {
    isValid() {
      return (
        this.password === this.passwordConfirmation && this.password.length > 3
      );
    },
  },
};
</script>
